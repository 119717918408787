.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 20px 0;
}
h1 {
  font-size: 45px;
  color: white !important;
  margin-bottom: 0 !important;
}
.portfolio-title {
  color: #000;
  font-size: 30px;
  font-weight: 100;
}
section {
  /* background-image: linear-gradient(#afc2d4, #d7e1ea); */
  /* height: calc(100vh - 60px); */
  padding-top: 60px;
  min-height: auto;
}
.flex-grid-thirds {
  width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex-grid-thirds .col {
  width: 32%;
  margin: 1% 0;
}
.thumbnail {
  width: 100%;
  height: 200px;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-card-body {
  padding: 0 !important;
}
.ant-card-bordered {
  border: 1px solid #f0f0f0 !important;
}
.bottom-wrapper {
  /* border-top: 1px solid #cecece; */
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.skills {
  text-align: left;
  padding: 10px 20px;
  font-size: 18px;
  margin-bottom: 0;
}
@media (max-width: 1000px) {
  .flex-grid-thirds {
    width: 760px;
  }
  .flex-grid-thirds .col {
    width: 48%;
    margin: 2% 0;
  }
}
@media (max-width: 768px) {
  .flex-grid-thirds {
    width: 500px;
    justify-content: center;
  }
  .flex-grid-thirds .col {
    width: 80%;
    margin: 2% 0;
  }
}
@media (max-width: 520px) {
  h1 {
    font-size: 30px;
  }
  .portfolio-title {
    font-size: 25px;
  }
  .App-header {
    padding: 15px 0;
  }
  section {
    padding-top: 20px;
  }
  .flex-grid-thirds {
    width: 94%;
    justify-content: center;
  }
  .flex-grid-thirds .col {
    width: 100%;
    margin: 2% 0;
  }
}
